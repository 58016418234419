import i18n from "@/core/plugins/vue-i18n";

export function getStatusIcon(status, key = "job", startedAt = null) {
  switch (status) {
    case "process.stopped":
      return "fas fa-circle yedi-green";
    case "process.error":
      return "fas fa-circle yedi-red";
    case "process.aborted": {
      let prefix = "fas";
      if (startedAt === undefined || startedAt === null) {
        prefix = "fal";
      }
      return prefix + " fa-circle yedi-red";
    }
    case "process.pending":
      return "fas fa-circle";
    case "process.working":
      return "fas fa-circle yedi-blue";
    case "process.paused":
      return "fas fa-circle yedi-turquoise";
    case "process.warning":
      if (key === "log") {
        return "fas fa-circle yedi-orange";
      }
      return "fal fa-circle yedi-orange";
    default:
      return "fas fa-circle";
  }
}

export function getStatusColor(status) {
  switch (status) {
    case "process.stopped":
      return "yedi-green";
    case "process.error":
      return "yedi-red";
    case "process.aborted":
      return "yedi-red";
    case "process.working":
      return "yedi-orange";
    case "process.paused":
      return "yedi-cyan";
    case "process.warning":
      return "yedi-orange";
    case "process.pending":
    default:
      return "";
  }
}

export function getStatusTooltipText(status, key = "job") {
  switch (status) {
    case "process.stopped":
      return i18n.t("workflowDesigner." + key + "StatusStopped");
    case "process.error":
      return i18n.t("workflowDesigner." + key + "StatusError");
    case "process.pending":
      return i18n.t("workflowDesigner." + key + "StatusPending");
    case "process.working":
      return i18n.t("workflowDesigner." + key + "StatusWorking");
    case "process.aborted":
      return i18n.t("workflowDesigner." + key + "StatusAborted");
    case "process.warning":
      return i18n.t("workflowDesigner." + key + "StatusWarning");
    case "process.info":
      return i18n.t("workflowDesigner." + key + "StatusInfo");
    default:
      return i18n.t("workflowDesigner." + key + "StatusWarning");
  }
}
