var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row justify-center"},[_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
      _setup.getStatusTooltipText(_vm.data.item[_vm.field.key], _vm.field.statusType)
    ),expression:"\n      getStatusTooltipText(data.item[field.key], field.statusType)\n    ",modifiers:{"hover":true,"top":true}}],class:_setup.getStatusIcon(
        _vm.data.item[_vm.field.key],
        _vm.field.statusType,
        _vm.data.item.started_at
      )}),(_vm.data.item.warnings_count >= 1)?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"warning"}},[_vm._v(_vm._s(_vm.data.item.warnings_count)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }